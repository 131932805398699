import Swiper, { Autoplay, Pagination } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let profilesSwiperWr = document.querySelectorAll('.profiles-swiper-wr');

profilesSwiperWr.forEach((el) => {

  if (el) {
    let swiperEl = el.querySelector('.swiper');
    /*let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');*/
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Pagination],
      spaceBetween: 30,
      slidesPerView: 'auto',
      speed: 800,
      threshold: 10,
      /*navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },*/
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
      breakpoints: {
      },

      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      },
    });
    swiperObserver(swiper);
  }
});
