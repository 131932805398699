import Plyr from 'plyr';
import scrollLock from "scroll-lock/dist/scroll-lock.js";

/**/
let plyr;
let videoCards = document.querySelectorAll('[data-video-card]');


window.initPopupPlyr = function initPopupPlyr (video){
    plyr =  new Plyr(video, {
        controls:['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'airplay', 'fullscreen']
    })
    plyr.on('play', function(){
        this.classList.add('started');
    })
}

window.closeVideoPopup = function closeVideoPopup (plyr){
   /* plyr.pause();*/
    $(videoPopup).fadeOut(function(){
            scrollLock.enablePageScroll();
    });
}

window.showVideoPopup = function(){
    $(videoPopup).fadeIn({
        start: function(){
            $(this).css('display', 'flex');
            scrollLock.disablePageScroll();
        }
    })
}

// closePopupBtn.addEventListener('click',  function(){
//     closeVideoPopup(plyr)
// });
//
// videoPopupUnderlay.addEventListener('click',  function(){
//     closeVideoPopup(plyr)
// });

/*
if(videoCards){
    videoCards.forEach(card => {
        card.addEventListener('click', showVideoPopup);
    })
}*/

